import React from "react";

const Hero = ({ handleonclick }) => {
  return (
    <div id="home" className="relative w-full h-screen">
      <a
        href="https://bit.ly/ggnowww"
        target="_blank"
        rel="noopener noreferrer"
        className="absolute inset-0 z-20"
      >
        <video
          src="https://videos.pexels.com/video-files/2049015/2049015-hd_2048_1024_30fps.mp4"
          className="w-full h-full object-cover rounded-lg shadow-lg"
          autoPlay
          loop
          muted
        />
      </a>
      <div className="absolute bottom-0 z-30 md:px-32 w-full h-[20vh]   flex items-end justify-center">
        <button
          onClick={() => handleonclick("https://bit.ly/ggnowww")}
          className="bg-yellow-500 animate-bounce border-gray-600 border-[7px] px-20 py-4 rounded-md uppercase text-white mb-10"
        >
          Get ID Now
        </button>
      </div>
    </div>
  );
};

export default Hero;
