import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Navbar from "./Navbar";
import Features from "./Features";
import ContactPage from "./Contact";
import Hero  from "./Hero"
const Homepage = () => {

  useEffect(() => {
    ReactGA.initialize("G-9CGZ04CXBH");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/ggnowww", "_blank");
  };

  return (
    <div>
      <Navbar handleonclick={handleonclick} />
      <Hero handleonclick={handleonclick} />
      <Features handleonclick={handleonclick}/>
      <ContactPage handleonclick={handleonclick} />
    </div>
  );
};

export default Homepage;
