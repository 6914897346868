import React from "react";

const ContactPage = ({handleonclick}) => {
  return (
    <div
      className="min-h-screen flex items-center justify-center px-4"
      style={{
        backgroundImage: "url('https://img.freepik.com/free-vector/golden-realistic-dice-glowing-lights-background_1017-23673.jpg?t=st=1733572620~exp=1733576220~hmac=0c26822e6ebb91ab555598f95e44783fd6eae6e0abf1b99016af43b4d0cfe977&w=1060')",
        backgroundSize: "cover",
      }}
    >
      <div className="bg-transparent p-8 rounded-lg shadow-xl w-full max-w-lg">
        <h1 className="text-3xl font-extrabold text-center text-white mb-6">
          Customer Care
      
        </h1>
        <p className="text-lg text-white text-center">
            {" "}
            For any inquiry and assistance, please feel free to contact us, our
            customer service is open 24 hours a everyday
          </p>
        <form className="space-y-6">
          {/* Name Field */}
          <div>
            <label
              htmlFor="name"
              className="block text-lg font-medium text-gray-100"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              className="mt-2 block w-full px-4 py-3 rounded-lg border-gray-300 shadow-sm text-gray-900 text-lg focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Mobile Field */}
          <div>
            <label
              htmlFor="mobile"
              className="block text-lg font-medium text-gray-100"
            >
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              placeholder="Enter your mobile number"
              className="mt-2 block w-full px-4 py-3 rounded-lg border-gray-300 shadow-sm text-gray-900 text-lg focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          {/* Message Field */}
          <div>
            <label
              htmlFor="message"
              className="block text-lg font-medium text-gray-100"
            >
              Message
            </label>
            <textarea
              id="message"
              rows="4"
              placeholder="Write your message here"
              className="mt-2 block w-full px-4 py-3 rounded-lg border-gray-300 shadow-sm text-gray-900 text-lg focus:border-indigo-500 focus:ring-indigo-500"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
          onClick={handleonclick}
            type="submit"
            className="w-full bg-yellow-500 text-white py-3 rounded-lg text-lg font-bold shadow-md hover:shadow-xl transform hover:scale-105 transition-all duration-300"
          >
            Submit
          </button>
        </form>

        {/* Social Links */}
        <div className="flex justify-center space-x-6 mt-8"></div>
      </div>
    </div>
  );
};

export default ContactPage;
